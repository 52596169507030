import { err, Ok, ok, Result } from "neverthrow";

/**
 * Similar to `JSON.stringify`, but limited to a specified depth.
 *
 * Adapted from https://gist.github.com/bennettmcelwee/06f0cadd6a41847f848b4bd2a351b6bc
 */
export function stringifyToDepth(obj: any, depth: number): string {
  if (!obj) {
    return JSON.stringify(obj);
  }

  if (typeof obj !== "object") {
    return JSON.stringify(obj);
  }

  const contents =
    depth < 1
      ? "…"
      : Object.keys(obj)
          .map((k) => k + ": " + stringifyToDepth(obj[k], depth - 1))
          .join(", ");

  return `{${contents}}`;
}

/**
 * A safer version of `JSON.parse`.
 *
 * - Uses `unknown` instead of `any`.
 * - Wraps the return value in a `Result` object instead of throwing.
 */
export function parseJSON(json: string): Result<unknown, Error> {
  try {
    return ok(JSON.parse(json));
  } catch (e) {
    // We can be relatively sture that `JSON.parse` throws an `Error`, and not some other value.
    return err(e as Error);
  }
}

/**
 * Perform `JSON.parse` on a list of strings, and skip any strings that are not valid JSON.
 */
export function parseJsonItemsAndSkipFailures<T>(items: string[]): T[] {
  return items
    .map((item) => parseJSON(item))
    .filter((result) => result.isOk())
    .map((result) => (result as Ok<T, never>).value);
}
