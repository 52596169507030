
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "build": {
    "user": "root",
    "host": "2f0dfbc0b5a5",
    "time": "2024/07/04 21:21:45",
    "commit": "7766c7a06581bd08a2c06dd2412c0e8a894b4313",
    "repo": "git@bitbucket.org:rscdevelopers/vv-public.git",
    "branch": "master",
    "release": "7766c7a06581bd08a2c06dd2412c0e8a894b4313"
  },
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
