/**
 * Make a human-readable list of words using commas and the word "and".
 *
 * TODO: Replace this with something from the `i18n` library?
 *
 * From https://stackoverflow.com/a/53879103/836995
 *
 * @param items
 *   The array of strings to concatenate into a list.
 * @returns
 *   A single string containing all items.
 */
export function makeListString(items: string[]): string {
  if (items.length === 0) return "";
  if (items.length === 1) return items[0] || "";
  const firsts = items.slice(0, items.length - 1);
  const last = items[items.length - 1];
  return firsts.join(", ") + " and " + last;
}

/**
 * Extract text from HTML.
 *
 * From https://stackoverflow.com/a/47140708/836995
 *
 * @param html
 *   Source code
 * @returns
 *   The text extracted from the HTML source.
 */
export function strip(html: string): string {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

/**
 * Convert all hyphens and "em dashes" to "en dashes" (or the given character,
 * if specified). It also makes sure to use a character, not an HTML entity.
 *
 * @param str
 *   The string to convert.
 * @param dash
 *   The dash to replace with. Optional. Defaults to "en dash".
 * @return
 *   A string with dashes replaced.
 */
export function replaceDashes(str: string, dash = "–"): string {
  return str.replace(/[-–—]|&#45;|&#8211;|&#8212;|&mdash;|&ndash;/g, dash);
}

/**
 * Shorten string. Replace removed characters with ellipsis.
 *
 * Ellipsize is a neologism. See https://stackoverflow.com/a/13314069/836995 .
 *
 * @param str
 *   The original string.
 * @param maxLength
 *   The maximum number of characters in the resulting string.
 *
 * @returns
 *   The shortened string.
 */
export function ellipsize(str: string, maxLength = 50): string {
  return str.length <= maxLength ? str : str.substring(0, maxLength - 1) + "…";
}

/**
 * Returns true if the needle is contained in the haystack. Case-insensitive.
 */
export function textContains(haystack: string, needle: string): boolean {
  return haystack.toLocaleLowerCase().includes(needle.toLocaleLowerCase());
}

export function parseIntWithFallback(value: string, fallback: number): number {
  const result = parseInt(value);
  return isNaN(result) ? fallback : result;
}
