import { type ThemeInstance } from "vuetify";
import {
  type PreferencesStore,
  type Theme,
  usePreferencesStore,
} from "~/stores/usePreferencesStore";

/**
 * Keep Vuetify settings in sync with the `preferences` store.
 */
export function keepThemePreferencesSynced(
  theme: ThemeInstance,
  preferencesStore: PreferencesStore,
) {
  watch(
    () => preferencesStore.theme,
    (newTheme: Theme) => {
      if (newTheme === "auto") {
        theme.global.name.value = detectTheme() ?? fallbackTheme;
      } else {
        // Use the user's preference.
        theme.global.name.value = newTheme;
      }
    },
    { immediate: true },
  );
}

export const fallbackTheme = "light";

export function detectTheme(): "light" | "dark" | null {
  if (process.client) {
    // Use the browser's preference.
    return detectThemeFromBrowser();
  }
  if (process.server) {
    // Try to detect the user's preference from the request.
    return detectThemeFromRequest();
  }
  return null;
}

/**
 * Detects the user's preferred theme from their browser settings.
 * Fall back to "light" if the query fails.
 */
function detectThemeFromBrowser(): "light" | "dark" | null {
  if (window.matchMedia) {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  }
  return null;
}

/**
 * Detect the user's preferred theme from the `Sec-CH-Prefers-Color-Scheme` client hint header.
 * Fall back to "light" if no such header exists.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-CH-Prefers-Color-Scheme
 */
function detectThemeFromRequest(): "light" | "dark" | null {
  // This probably won't work yet, because:
  // - Many browsers don't support it yet: https://github.com/mozilla/standards-positions/issues/526
  // - Nuxt does not support it yet: https://github.com/nuxt/nuxt/issues/23563
  switch (useRequestHeader("Sec-CH-Prefers-Color-Scheme")) {
    case "light":
      return "light";
    case "dark":
      return "dark";
    default:
      return null;
  }
}

/**
 * Get the value of `defaultTheme` to use in `createVuetify({theme:{defaultTheme}})`.
 *
 * If the server detects one theme, but the browser detects another, we sometimes end up with a mixture of the two, and
 * some hydration errors. To avoid this, we check if the server has detected a theme, and use that as the default theme.
 * If the default theme is incorrect, it will be switched to the correct theme in the `onMounted` hook of `app.vue`.
 * This causes a flash of the wrong theme, but it's better than a hydration error.
 */
export function getDefaultThemeForVuetify(): "light" | "dark" {
  if (process.server) {
    const preferredThemeFromCookie = usePreferencesStore().theme;
    let finalTheme: "light" | "dark";
    if (preferredThemeFromCookie === "auto") {
      finalTheme = detectThemeFromRequest() ?? fallbackTheme;
    } else {
      finalTheme = preferredThemeFromCookie;
    }
    // Tell the client which theme was used for SSR.
    useNuxtApp().payload.ssrTheme = finalTheme;
    return finalTheme;
  }
  if (process.client) {
    // To prevent hydration errors, use the same theme that was used during SSR.
    const payloadTheme = useNuxtApp().payload.ssrTheme;
    return payloadTheme === "light" || payloadTheme === "dark"
      ? payloadTheme
      : fallbackTheme;
  }
  return fallbackTheme;
}
