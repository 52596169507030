import { PiniaSharedState } from "pinia-shared-state";
import { type Pinia } from "pinia";
import { migrateVuexDataToPinia } from "~/stores/migrateVuexDataToPinia";
import { useReadingListsStore } from "~/stores/useReadingListsStore";

export default defineNuxtPlugin((nuxtApp) => {
  const pinia = nuxtApp.$pinia as Pinia;

  // Share Pinia state between browser tabs.
  pinia.use(PiniaSharedState({ enable: false }));

  // Migrate old Vuex data to Pinia in localstorage.
  if (window.localStorage) {
    migrateVuexDataToPinia(window.localStorage, useReadingListsStore());
  }
});
