import { type Result, type ResultAsync } from "neverthrow";
import { computed, reactive, type Ref, ref, watch } from "vue";

/**
 * A more convenient way to use `ResultAsync` objects in Vue components.
 *
 * @param getter A function that returns a `ResultAsync` object. It may not throw.
 *
 * @returns An object with lots of useful properties for working with the result.
 */
export function useComputedResultAsync<T, E>(
  getter: () => ResultAsync<T, E>,
): ComputedResultAsync<T, E> {
  const resultAsync = computed(getter);

  const isPending = ref<boolean>(false);
  const isSettled = ref<boolean>(false);

  const isOutdated = computed(() => isSettled.value && isPending.value);

  // From https://github.com/vuejs/core/issues/2136#issuecomment-693524663 :
  // "You need to cast to as Ref<T> ... if you sure that the type doesn't have any nested refs ..."
  const result = ref<Result<T, E> | undefined>(undefined) as Ref<
    Result<T, E> | undefined
  >;

  watch(
    resultAsync,
    (ra) => {
      isPending.value = true;

      ra.then((v) => {
        if (ra !== resultAsync.value) {
          // This result is outdated. Do nothing.
          return;
        }
        isPending.value = false;
        isSettled.value = true;
        result.value = v;
      });
      // No catch clause, because ResultAsync is supposed to never reject and never throw.
    },
    { immediate: true },
  );

  return reactive({
    resultAsync,
    result,
    isPending,
    isSettled,
    isOutdated,
    value: computed(() =>
      result.value?.isOk() ? result.value.value : undefined,
    ),
    error: computed(() =>
      result.value?.isErr() ? result.value.error : undefined,
    ),
  });
}

export interface ComputedResultAsync<T, E> {
  /**
   * The ResultAsync that is currently resolved or being resolved.
   */
  readonly resultAsync: ResultAsync<T, E>;

  /**
   * The last resolved result. If resultAsync is still pending, and this is not undefined, then this is outdated.
   */
  readonly result: Result<T, E> | undefined;

  /**
   * Whether a new result is pending (not resolved yet).
   */
  readonly isPending: boolean;

  /**
   * Whether a result has been settled (resolved, because it can't reject).
   */
  readonly isSettled: boolean;

  /**
   * Whether the last result has been resolved, but a new one is pending.
   */
  readonly isOutdated: boolean;

  /**
   * Convenience accessor for `result.value`, bypassing the need to check `isOk()`.
   */
  readonly value: T | undefined;

  /**
   * Convenience accessor for `result.error`, bypassing the need to check `isErr()`.
   */
  readonly error: E | undefined;
}
