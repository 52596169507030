import { type RouterConfig } from "@nuxt/schema";
import { type Component } from "vue";
import { type RouteRecordRaw } from "vue-router";
import { bibleRouteNameRegex } from "~/routing/regex";

/**
 * A mapping of route names (generated from the `pages` directory structure) to
 * toolbar components.
 */
const toolbars: Record<string, Component> = {
  "bible-vids": () => import("~/toolbars/BibleToolbar.vue"),
  "commentary-vids-type": () => import("~/toolbars/CommentaryToolbar.vue"),
};

const routeNameLocalizationSuffixRegex = /___[a-z]{2}$/;

export default <RouterConfig>{
  routes: (originalRoutes) => {
    return originalRoutes.map(setRouteComponents);
  },

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // This is triggered by the browser's back or forward button.
      return savedPosition;
    } else if (
      bibleRouteNameRegex.test(String(to.name ?? "")) &&
      bibleRouteNameRegex.test(String(from.name ?? ""))
    ) {
      // Don't scroll to the top when navigating within the bible page.
      return false;
    } else {
      // This is a new navigation. Scroll to the top.
      return { left: 0, top: 0 };
    }
  },
};

function setRouteComponents(route: RouteRecordRaw) {
  if (!route.component) {
    return route;
  }

  const components: Record<string, Component> = {
    default: route.component,
  };

  const name = String(route.name).replace(routeNameLocalizationSuffixRegex, "");
  const toolbar = toolbars[name];
  if (toolbar) {
    components.toolbar = toolbar;
  }

  return {
    ...route,
    component: undefined,
    components,
  };
}
