import { type Client } from "@sentry/types";
import { Scope } from "@sentry/core";
import { type showReportDialog } from "@sentry/browser";
import {
  logLevels,
  type RfcLogLevelNumber,
  severities,
} from "~/logging/levels";

export class Logger {
  public readonly client: Client | undefined;
  public readonly promptForUserFeedback: typeof showReportDialog | undefined;

  constructor(
    client: Client | undefined,
    promptForUserFeedback: typeof showReportDialog | undefined,
  ) {
    this.client = client;
    this.promptForUserFeedback = promptForUserFeedback;
  }

  /**
   * Log a message.
   *
   * To prevent infinite loops with error logging, this function MUST NOT throw any error.
   *
   * @param level
   *   An integer representing a valid RFC log level.
   * @param message
   *   The message to log.
   * @param error
   *   The error associated with this message.
   * @param extra
   *   Any extra data, which will be converted to JSON and appended.
   *   Useful for logging things like the component state at the time of the error.
   * @param mayPromptForUserFeedback
   *   Whether to prompt the user for feedback about the error.
   *   Ignored if Sentry is not enabled.
   *   Ignored when running on the server side.
   *   See https://docs.sentry.io/platforms/javascript/enriching-events/user-feedback
   */
  public log(
    level: RfcLogLevelNumber,
    message: string,
    error: Error | null,
    extra: Record<string, unknown> | null,
    mayPromptForUserFeedback: boolean,
  ): void {
    if (this.client) {
      try {
        const eventId = logToSentry(this.client, level, message, error, extra);
        if (eventId && mayPromptForUserFeedback && this.promptForUserFeedback) {
          this.promptForUserFeedback({ eventId });
        }
        return;
      } catch (e) {
        // Fall through to console logging.
      }
    }

    try {
      return logToConsole(level, message, error, extra);
    } catch (e) {
      // Ignore.
    }
  }
}

function logToSentry(
  client: Client,
  logLevel: RfcLogLevelNumber,
  message: string,
  error: Error | null,
  extra: Record<string, unknown> | null,
): string | null {
  const level = severities[logLevel];
  const scope = new Scope();
  if (level) {
    scope.setLevel(level);
  }
  if (extra) {
    scope.setExtras(extra);
  }

  if (error) {
    scope.setExtra("message", message);
    return client.captureException(error, undefined, scope) ?? null;
  } else {
    return client.captureMessage(message, level, undefined, scope) ?? null;
  }
}

function logToConsole(
  logLevel: RfcLogLevelNumber,
  message: string,
  error: Error | null,
  extra: Record<string, unknown> | null,
): void {
  /* eslint-disable no-console */
  let f;
  if (logLevel <= logLevels.error) {
    f = console.error;
  } else if (logLevel <= logLevels.notice) {
    f = console.warn;
  } else if (logLevel <= logLevels.info) {
    f = console.info;
  } else if (logLevel <= logLevels.debug) {
    f = console.debug;
  } else {
    f = console.log;
  }

  console.group(message);
  if (error) {
    f(error);
  }
  if (extra) {
    f(extra);
  }
  console.groupEnd();
  /* eslint-enable no-console */
}
