import {
  type CountInRangesResult,
  type SolrClientError,
  type SolrClientInterface,
} from "@rsc/vv-solr-client";
import { type TranslationAgnosticVidRange } from "@rsc/scripture-util";
import { ResultAsync } from "neverthrow";
import { isDefined } from "../typescriptHelpers";
import {
  type CommentaryType,
  knownCommentaryTypes,
} from "~/static/taxonomy/commentaryTypes";

export interface CommentaryIndexCommentaryTypeCount {
  commentaryType: CommentaryType;
  count: number;
}

export interface CommentaryIndexRangeCount<
  T extends TranslationAgnosticVidRange,
> {
  range: T;
  count: number;
  commentaryTypes: CommentaryIndexCommentaryTypeCount[];
}

export type CommentaryIndexByRanges<T extends TranslationAgnosticVidRange> = {
  count: number;
  ranges: CommentaryIndexRangeCount<T>[];
};

/**
 * Get the commentary index for the given ranges.
 *
 * @param ranges
 *   The ranges for which we want to get the commentary index. This may be books, chapters, or arbitrary verses.
 * @param solr
 *   A solr client.
 * @param language
 *   Language of comments
 */
export function getCommentaryIndexByRanges<
  T extends TranslationAgnosticVidRange,
>(
  ranges: T[],
  solr: SolrClientInterface,
  language: string,
): ResultAsync<CommentaryIndexByRanges<T>, SolrClientError> {
  return solr.countInRanges(ranges, [language]).map((data) => {
    return {
      count: data.count,
      ranges: ranges.map(getMapRangeFunction(data)),
    };
  });
}

function getMapRangeFunction<T extends TranslationAgnosticVidRange>(
  solrResults: CountInRangesResult,
): (range: T) => CommentaryIndexRangeCount<T> {
  return function (range: T): CommentaryIndexRangeCount<T> {
    const bucket = (solrResults.vidRanges?.buckets || []).find(
      (b) => b.val === `[${range.fromVid},${range.toVid}]`,
    );

    return {
      range,
      count: bucket?.count || 0,
      commentaryTypes: (bucket?.commentaryType?.buckets || [])
        .map(mapCommentaryType)
        .filter(isDefined)
        .sort((a, b) => a.commentaryType.weight - b.commentaryType.weight),
    };
  };
}

function mapCommentaryType(solrBucket: {
  count: number;
  val: string;
}): CommentaryIndexCommentaryTypeCount | undefined {
  const commentaryType = knownCommentaryTypes.find(
    (t) => t.shortName === solrBucket.val,
  );
  if (!commentaryType) {
    return;
  }

  return { commentaryType, count: solrBucket.count };
}
