import ExpiryMap from "expiry-map";
import { type ResultAsync } from "neverthrow";

/**
 * A generic cache for clients that return ResultAsync objects.
 */
export class ResultAsyncCache<T, E> extends ExpiryMap<
  string,
  ResultAsync<T, E>
> {
  set(key: string, value: ResultAsync<T, E>): this {
    const result = super.set(key, value);

    value.then((result) => {
      if (result.isOk()) {
        // This is not an error.
        return;
      }

      if (
        errorHasResponseWithStatus(result.error) &&
        result.error.response.status < 500
      ) {
        // This is a 4xx response, and should not be removed from the cache, because the same bad request should always
        // get the same error response.
        return;
      }

      // Remove the following from the cache:
      // - Server errors (5xx), because the same request might get a different response next time (e.g., if the server
      //   was fixed).
      // - Any other Exception.
      this.delete(key);
    });

    return result;
  }
}

function errorHasResponseWithStatus(
  error: unknown,
): error is { response: { status: number } } {
  return (
    typeof error === "object" &&
    error !== null &&
    "response" in error &&
    typeof error.response === "object" &&
    error.response !== null &&
    "status" in error.response &&
    typeof error.response.status === "number"
  );
}
