import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_Y6agwlnXZq from "/opt/atlassian/pipelines/agent/build/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/opt/atlassian/pipelines/agent/build/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import composition_sLxaNGmlSL from "/opt/atlassian/pipelines/agent/build/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/opt/atlassian/pipelines/agent/build/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_AUP22rrBAc from "/opt/atlassian/pipelines/agent/build/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import index_HhzN713Fmv from "/opt/atlassian/pipelines/agent/build/app/plugins/clients/index.ts";
import dragScroll_mNfyYmmob5 from "/opt/atlassian/pipelines/agent/build/app/plugins/dragScroll.ts";
import logging_client_TbQwm2r8zf from "/opt/atlassian/pipelines/agent/build/app/plugins/logging.client.ts";
import performanceTracing_client_5Zap3MmyZw from "/opt/atlassian/pipelines/agent/build/app/plugins/performanceTracing.client.ts";
import piniaExtras_client_ZeL8dRNdg0 from "/opt/atlassian/pipelines/agent/build/app/plugins/piniaExtras.client.ts";
import pwaRegister_client_fGrFEnnnGH from "/opt/atlassian/pipelines/agent/build/app/plugins/pwaRegister.client.ts";
import routeStateBindings_wrcdIAU7pF from "/opt/atlassian/pipelines/agent/build/app/plugins/routeStateBindings.ts";
import vue_query_wrmMkNpEpe from "/opt/atlassian/pipelines/agent/build/app/plugins/vue-query.ts";
import vuetify_7h9QAQEssH from "/opt/atlassian/pipelines/agent/build/app/plugins/vuetify.ts";
import window_client_kn8L8U1g2M from "/opt/atlassian/pipelines/agent/build/app/plugins/window.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_AUP22rrBAc,
  index_HhzN713Fmv,
  dragScroll_mNfyYmmob5,
  logging_client_TbQwm2r8zf,
  performanceTracing_client_5Zap3MmyZw,
  piniaExtras_client_ZeL8dRNdg0,
  pwaRegister_client_fGrFEnnnGH,
  routeStateBindings_wrcdIAU7pF,
  vue_query_wrmMkNpEpe,
  vuetify_7h9QAQEssH,
  window_client_kn8L8U1g2M
]