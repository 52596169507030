import {
  type ReadingList,
  type ReadingListsStore,
} from "./useReadingListsStore";
import { parseJSON } from "~/units/json";

export function migrateVuexDataToPinia(
  localStorage: Storage,
  readingListsStore: ReadingListsStore,
): void {
  const stored = localStorage.getItem("vuex");
  if (!stored) {
    return;
  }

  const parsedResult = parseJSON(stored);
  if (parsedResult.isErr()) {
    // JSON is not parseable. We might as well remove it.
    localStorage.removeItem("vuex");
    return;
  }

  const oldVuexData = parsedResult.value;
  if (!oldVuexData || typeof oldVuexData !== "object") {
    localStorage.removeItem("vuex");
    return;
  }

  // The reading lists is the only important thing.
  migrateReadingLists(oldVuexData, readingListsStore);

  localStorage.removeItem("vuex");
}

function migrateReadingLists(
  oldVuexData: any,
  readingListsStore: ReadingListsStore,
): void {
  const oldReadingListsData: unknown = oldVuexData?.lists?.readingLists;
  if (!oldReadingListsData || typeof oldReadingListsData !== "object") {
    return;
  }

  Object.values(oldReadingListsData).forEach((list) =>
    migrateReadingList(readingListsStore, list),
  );
}

function migrateReadingList(
  readingListsStore: ReadingListsStore,
  list: unknown,
): void {
  if (!isReadingList(list)) {
    // Not valid.
    return;
  }

  if (readingListsStore.readingLists[list.name]) {
    // Already exists.
    return;
  }

  // Valid
  readingListsStore.readingLists[list.name] = list;
}

export function isReadingList(obj: any): obj is ReadingList {
  // This is not an exhaustive check, but probably good enough.
  return (
    obj &&
    typeof obj === "object" &&
    typeof obj.title === "string" &&
    typeof obj.name === "string" &&
    typeof obj.createdDate === "number" &&
    Array.isArray(obj.items)
  );
}
