/**
 * Check if the given value is defined and not `null`.
 *
 * Useful as a callback function like `arr.filter(isDefined)` to narrow `T | undefined | null` to `T`.
 *
 * See https://stackoverflow.com/a/62753258/836995
 */
export function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}

/**
 * Check if the value of the given key-value pair is defined and not `null`.
 *
 * Useful as a callback function like `Object.entries(obj).filter(isValueDefined)` to narrow
 * `[string, (T | undefined | null)[]][]` to `[string, T[]][]`.
 */
export function isValueDefined<K, V>(val: [K, V | undefined]): val is [K, V] {
  return isDefined(val[1]);
}

/**
 * Check if an object has a property, in a way that Typescript will respect, without having to cast to `any`.
 *
 * See https://stackoverflow.com/a/69705643/836995
 */
export function hasProperty<Prop extends string>(
  obj: unknown,
  prop: Prop,
): obj is Object & Record<Prop, unknown> {
  return obj instanceof Object && prop in obj;
}

/**
 * Get a property from an object, if it exists. If it does not exist, or it's not an object, return `undefined`.
 *
 * This is useful for working with things that are typed `unknown`, without having to cast to `any`.
 */
export function getProperty(obj: unknown, prop: string): unknown {
  if (hasProperty(obj, prop)) {
    return obj[prop];
  }
  return undefined;
}
