import { type RouteLocationNormalized } from "vue-router";
import {
  type LocationQueryAndParams,
  useStateRouteBinding,
} from "~/composables/useStateRouteBinding";
import {
  getStringParam,
  getStringQueryParameter,
  type UpdateQueryAndParamsFunction,
} from "~/routing/routes";
import {
  bibleStateHasChanged,
  bibleStateIsReady,
  type BibleStore,
  type BibleStoreRouteState,
} from "~/stores/useBibleStore";
import { getBibleLocationFromState } from "~/routing/locations";
import { bibleRouteNameRegex } from "~/routing/regex";
import { never } from "~/never";

/**
 * Create a two-way binding between bible state and the route.
 */
export function bindBibleAndRoute(
  store: BibleStore,
  getRoute: () => Pick<RouteLocationNormalized, "name" | "query" | "params">,
  updateQueryAndParams: UpdateQueryAndParamsFunction,
): void {
  useStateRouteBinding<BibleStoreRouteState>(
    () => {
      const state: Partial<BibleStoreRouteState> = {
        vids: store.vids,
        tids: store.tids,
      };
      return bibleStateIsReady(state) ? state : undefined;
    },
    (value) => store.setRouteState(value),
    bibleStateHasChanged,
    getRoute,
    updateQueryAndParams,
    bibleRouteNameRegex,
    getBibleLocationFromState,
    getDesiredBibleStateFromLocation,
  );
}

/**
 * Given the current route's query and parameters, figure out what the Bible state should be.
 * Fall back to values from the previous state where the route does not specify a value.
 *
 * @param location The current route's query and parameters.
 * @param previousState The previous Bible state.
 *
 * @returns The desired Bible state.
 */
export function getDesiredBibleStateFromLocation(
  location: LocationQueryAndParams,
  previousState: BibleStoreRouteState | undefined,
): BibleStoreRouteState {
  return {
    tids:
      (getStringQueryParameter(location, "tids") || previousState?.tids) ??
      never("Unknown translation IDs in `getDesiredBibleStateFromLocation`."),
    vids:
      (getStringParam(location, "vids") || previousState?.vids) ??
      never("Unknown verse IDs in `getDesiredBibleStateFromLocation`."),
  };
}
