<template lang="pug">
VAlert.error-alert(type="error")
  div(v-if="message") {{ message }}

  pre.mt-4.the-error__stack.overflow-auto(v-if="stack && !stackIsHtml") {{ stack }}
  div.mt-3.the-error__stack.overflow-auto(
    v-if="stack && stackIsHtml"
    v-html="stack"
  )

  slot

  div.d-flex.gap-4.mt-4
    VSpacer

    VBtn(
      v-if="hasListener('retry')"
      title="Retry"
      aria-label="Retry"
      @click="emit('retry')"
    )
      VIcon.mr-2(size="small") {{ mdiRefresh }}
      | Retry

    VBtn(
      v-if="reporting"
      title="Report"
      aria-label="Report"
      @click="report"
    )
      VIcon.mr-2(size="small") {{ mdiBug }}
      | Report
</template>

<script setup lang="ts">
/**
 * Use this for an error that is not the only thing on the page.
 */

import { mdiBug, mdiRefresh } from "@mdi/js";
import { hasListener } from "~/composables/useProps";
import { injectRequired } from "~/injectRequired";
import { LoggerKey } from "~/injectionKeys";
import { type ErrorReportingInfo } from "~/components/errorAlert.util";
import { logLevels } from "~/logging/levels";

interface Props {
  /**
   * The error message, as a string.
   * This is useful when we don't have a full `Error` object.
   */
  message?: string;

  /**
   * The error stack, as a string.
   * This is useful when we don't have a full `Error` object.
   */
  stack?: string;

  /**
   * Whether the stack is HTML. If `false`, the stack is treated as plain text and placed in a `<pre>` tag.
   */
  stackIsHtml?: boolean;

  /**
   * Sentry error reporting.
   * - If this is `false`, the report button is hidden.
   * - If this is an object, the report button is shown.
   */
  reporting?: false | ErrorReportingInfo;

  /**
   * The HTTP status code to use if this component exists in SSR or pre-rendering.
   * Assuming there is only one ErrorAlert on the page, the HTTP response for that page will have this status code.
   * A page with a status code >= 400
   * - should not be cached in CDNs or in browsers.
   * - should not be included in the pre-rendered build.
   */
  statusCode?: number;
}

const props = withDefaults(defineProps<Props>(), {
  reporting: false,
  statusCode: 500,
  stackIsHtml: false,
});

if (process.server) {
  // We are performing server-side rendering or pre-rendering. Set the HTTP response status code.
  const event = useRequestEvent(); // This returns `undefined` in the browser.
  if (event) {
    setResponseStatus(event, props.statusCode, props.message ?? "Error");
  }
}

interface Emits {
  (e: "retry"): void;
}

const emit = defineEmits<Emits>();

const logger = injectRequired(LoggerKey);

function report() {
  if (!props.reporting) {
    return;
  }

  logger.log(
    logLevels.error,
    props.reporting.message ?? "User reported error",
    props.reporting.error ?? null,
    { reportedManually: true, ...props.reporting.extra },
    true,
  );
}
</script>
