import {
  createLocaleFromRouteGetter,
  type LocaleObject,
} from "vue-i18n-routing";

/**
 * Locales and their configuration.
 *
 * Neither the `nuxt-i18n` module nor the `vue-i18n` module provide an easy way to get the config of the current locale
 * from `nuxt.config.ts` at runtime. As a workaround, we place the config here, so that we can simply import it, both
 * from `nuxt.config.ts` and from elsewhere.
 */
export const locales: VvLocaleObject[] = [
  {
    code: "en",
    iso: "en",
    name: "English",
    isCatchallLocale: true,
    defaultTid: "esv",
  },
  {
    code: "fr",
    iso: "fr",
    name: "Français",
    // FIXME: Get better translation IDs in the scripture server (keep the old ones for backward compatibility).
    //  "FRC" is not a good ID for NEG79. It should be "neg79" instead.
    //  See https://bitbucket.org/rscdevelopers/scripture-server/src/master/translations/
    defaultTid: "frc",
  },
];

export const localeCodes = locales.map((l) => l.code);

/**
 * A map of locales by their code.
 */
export const localesByCode = new Map<string, VvLocaleObject>(
  locales.map((l) => [l.code, l]),
);

export const getLocaleFromRoute = createLocaleFromRouteGetter(
  localeCodes,
  "___",
  "default",
);

export interface VvLocaleObject extends LocaleObject {
  /**
   * The ID of the default translation for this locale. The language of this
   * translation should be the same as the language of the user interface.
   */
  defaultTid: string;
}
