<template lang="pug">
span
  VMenu.index-study-menu(v-if="index.value?.count")
    template(#activator="activatorProps")
      slot(
        name="activator"
        v-bind="activatorProps"
      )
        VBtn.text-black(
          v-bind="activatorProps['props']"
          color="secondary-c500"
          variant="elevated"
        )
          VIcon {{ commentaryOutline }}
          | {{ i18n.t("Global.study") }}
    VList
      VListItem(
        v-for="{ commentaryType, count } in index.value.commentaryTypes"
        :key="commentaryType.shortName"
        :to="localePath(getCommentaryLocationFromRange(commentaryType.shortName, index.value.range))"
      )
        template(#prepend)
          CommentaryTypeIcon(
            :short-name="commentaryType.shortName"
            variant="outline"
          )
        VListItemTitle {{ commentaryType.name[locale] }}
        template(#append)
          VBadge(
            :content="count"
            :inline="true"
          )
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { type TranslationAgnosticVidRange } from "@rsc/scripture-util";
import { getCommentaryLocationFromRange } from "~/routing/locations";
import { commentaryOutline } from "~/icons";
import { injectRequired } from "~/injectRequired";
import { SolrClientKey } from "~/injectionKeys";
import { getCommentaryIndexByRanges } from "~/units/commentary/commentaryIndex";

const solrClient = injectRequired(SolrClientKey);

const i18n = useI18n();
const localePath = useLocalePath();

const locale = i18n.locale;

interface Props {
  range: TranslationAgnosticVidRange;
}

const props = defineProps<Props>();

const indexRefreshable = useRefreshableGetter(() =>
  getCommentaryIndexByRanges([props.range], solrClient, locale.value).map(
    (data) => data.ranges[0],
  ),
);

const index = useComputedResultAsync(indexRefreshable.getter);
</script>
